import haversine from 'haversine'
import isInsidePolygon from 'point-in-polygon'
import isNumber from 'lodash.isnumber'

export const getMostRecentUserPosition = (state) => {
    if (state.geoLocation.point && state.addressQuery.point) {
        if (state.geoLocation.timestamp > state.addressQuery.timestamp) {
            return state.geoLocation.point
        } else {
            return state.addressQuery.point
        }
    } else {
        return state.geoLocation.point || state.addressQuery.point
    }
}

export const getShapeIndexForUserPosition = (state) => {
    const selectedShapeIndex = state.map.selectedShapeIndex
    if (isNumber(selectedShapeIndex)) {
        return selectedShapeIndex
    }

    const userPosition = getMostRecentUserPosition(state)
    const shapes = state.map.shapes
    if (!userPosition || !shapes) {
        return null
    }

    let shapeIndex = -1
    shapes.some((shape, i) => {
        if (!shape.polygons) {
            return false
        }
        return shape.polygons.some((polygon) => {
            return polygon.some((polyline) => {
                if (isInsidePolygon(userPosition, polyline)) {
                    shapeIndex = i
                    return true
                }
                return false
            })
        })
    })

    return shapeIndex
}

export const getPinIndexForUserPosition = (state) => {
    const selectedPinIndex = state.map.selectedPinIndex
    if (isNumber(selectedPinIndex)) {
        return selectedPinIndex
    }

    const userPosition = getMostRecentUserPosition(state)
    const pins = state.map.pins
    if (!userPosition || !pins) {
        return null
    }

    const userPositionForCalculation = {
        latitude: userPosition[0],
        longitude: userPosition[1]
    }
    const distances = pins.map((pin) => {
        const positionForCalculation = {
            latitude: pin.point[0],
            longitude: pin.point[1]
        }
        return haversine(userPositionForCalculation, positionForCalculation)
    })
    const pinIndex = distances.indexOf(Math.min(...distances))
    if (pinIndex === -1) {
        throw new Error('calculation error')
    }
    return pinIndex
}

export const isSectorUnknown = (state) => {
    return getShapeIndexForUserPosition(state) === -1
}
