import React from 'react'
import { connect } from 'react-redux'

import { Loader } from 'issy-components'
import { appDataFetchRequest } from './redux/actions'
import { REQUEST_STATUSES } from './constants'
import {
    mapSetShapes,
    mapSetPins,
    mapResetPins,
    mapResetShapes
} from './redux/actions'
import {
    getShapeIndexForUserPosition,
    getPinIndexForUserPosition
} from './redux/selectors'

export const withAppData = (InnerComponent, sheetNames) => {
    class Component extends React.Component {
        componentDidMount() {
            if (this.props.sheetNamesToLoad.length) {
                this.props.appDataFetchRequest(this.props.sheetNamesToLoad)
            }
        }

        render() {
            const {
                sheetNamesToLoad,
                isDataLoaded,
                appData,
                appDataFetchRequest,
                ...passThroughProps
            } = this.props
            if (isDataLoaded) {
                return (
                    <InnerComponent appData={appData} {...passThroughProps} />
                )
            } else {
                return <Loader />
            }
        }
    }

    return connect(
        (state) => {
            const appData = {}
            sheetNames.forEach((sheetName) => {
                appData[sheetName] =
                    state.appData[sheetName] && state.appData[sheetName].data
            })
            const isDataLoaded = sheetNames.every((sheetName) => {
                const appDataSheet = state.appData[sheetName]
                return (
                    appDataSheet &&
                    appDataSheet.status === REQUEST_STATUSES.SUCCESS
                )
            })
            const sheetNamesToLoad = sheetNames.filter(
                (sheetName) => !state.appData[sheetName]
            )
            return {
                isDataLoaded,
                sheetNamesToLoad,
                appData
            }
        },
        { appDataFetchRequest }
    )(Component)
}

export const withShapesMap = (InnerComponent, solutionName) => {
    class Component extends React.Component {
        componentDidMount() {
            this.props.mapSetShapes(this.props.appData[solutionName])
        }

        componentWillUnmount() {
            this.props.mapResetShapes()
        }

        render() {
            return <InnerComponent {...this.props} />
        }
    }

    return connect(
        (state) => {
            const shapeIndex = getShapeIndexForUserPosition(state)
            const shapes = state.map.shapes
            return {
                mapShapes: shapes,
                mapSelectedShapeIndex: shapeIndex,
                mapSelectedShape:
                    shapeIndex !== null ? shapes[shapeIndex] : null
            }
        },
        { mapSetShapes, mapResetShapes }
    )(Component)
}

export const withPinsMap = (InnerComponent, solutionName) => {
    class Component extends React.Component {
        componentDidMount() {
            this.props.mapSetPins(this.props.appData[solutionName])
        }

        componentWillUnmount() {
            this.props.mapResetPins()
        }

        render() {
            return <InnerComponent {...this.props} />
        }
    }

    return connect(
        (state) => {
            const pinIndex = getPinIndexForUserPosition(state)
            const pins = state.map.pins
            return {
                mapPins: pins,
                mapSelectedPinIndex: pinIndex,
                mapSelectedPin: pinIndex !== null ? pins[pinIndex] : null
            }
        },
        { mapSetPins, mapResetPins }
    )(Component)
}

export const withPinsMapNoUserProximity = (InnerComponent, solutionName) => {
    class Component extends React.Component {
        componentDidMount() {
            this.props.mapSetPins(this.props.appData[solutionName])
        }

        componentWillUnmount() {
            this.props.mapResetPins()
        }

        render() {
            return <InnerComponent {...this.props} />
        }
    }

    return connect(
        (state) => {
            const pinIndex = state.map.selectedPinIndex
            const pins = state.map.pins
            return {
                mapPins: pins,
                mapSelectedPinIndex: pinIndex,
                mapSelectedPin: pinIndex !== null ? pins[pinIndex] : null
            }
        },
        { mapSetPins, mapResetPins }
    )(Component)
}