import React from 'react'
import styled from 'styled-components'

import { TwoColumnPage, Panel } from 'issy-components'
import RawHtml from './RawHtml'
import config from './config'
import { withAppData } from './hoc'
import pageConfigs from './page-configs'
import Header from './Header'

import aboutIllustration from './assets/issy-green-illus-SVG/issy-green-illu-about.svg'

const H2 = styled.h2`
    color: var(--colorOcean);
    font-size: 110%;
`

const TextLink = styled.a`
    font-weight: bold;
    border-bottom: 2px var(--colorOcean) solid;
    &:hover {
        border-color: var(--textBlack);
        color: var(--colorOceanLight);
    }
`

const PanelWithMargins = styled(Panel)`
    margin-top: 1em;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 2em;
    }
`

const LeftContent = () => {
    return (
        <div>
            Les collectivités locales de plus de 3 500 habitants ont
            l’obligation de publier, dans un format ouvert et exploitable, leurs
            principaux documents administratifs ainsi que tous les documents
            présentant un intérêt économique, social, sanitaire ou
            environnemental. Soucieuse d’avoir toujours un – voire deux – temps
            d’avance, la Ville d’Issy-les-Moulineaux a engagé sa démarche de
            transparence et d’open data (données sur des informations d'intérêt
            général accessibles à tous) en 2012.
            <br />
            <br />
            La singularité de la démarche d’Issy tient dans la volonté de
            démocratiser l’open data. En effet, en l’état, les fichiers « bruts
            » sont illisibles pour la plupart des non spécialistes. Pour les
            rendre accessibles au plus grand nombre, le portail open data a été
            éditorialisé et scénarisé à l’aide de cartes, graphiques, chiffres
            clés, galeries photos et vidéos.
            <br />
            <br />
            Dans ce contexte, Issy a créé, en collaboration avec les
            spécialistes de{' '}
            <TextLink href={config.WEDODATA_URL} target='_blank'>
                WEDODATA
            </TextLink>
            , cette visualisation pour permettre à tous de profiter des
            différents jeux de données mis en open data depuis plusieurs années
            par Ville et Grand Paris Seine Ouest, vous facilitant ainsi l’accès
            aux informations.
            <br />
            <br />
            Vous avez besoin d’air frais ? Découvrez toutes les balades autour
            de vous avec cette application qui s’appuie sur l’open data.
            <br />
            <br />
            Et si vous vous sentez l’âme d’un geek et que vous avez envie de «
            jouer » avec nos données, rendez-vous sur{' '}
            <TextLink href={config.ISSY_DATA_URL} target='_blank'>
                data.issy.com
            </TextLink>
            .
        </div>
    )
}

function About(props) {
    const solutionBoxes = props.appData.solution.map((solutionData) => {
        if (!pageConfigs[solutionData.name]) {
            return null
        }

        return (
            <PanelWithMargins
                clickUrl={`${config.ROOT_URL}/${solutionData.name}`}
                surtitle='À propos'
                title={solutionData.title}
                titleImageUrl={pageConfigs[solutionData.name].pageTitleIcon}
                footerText="Plus d'infos"
                key={solutionData.name}
            >
                <RawHtml html={solutionData.textIntro} />
                <H2>Le saviez-vous ?</H2>
                <RawHtml html={solutionData.didYouKnow} />
            </PanelWithMargins>
        )
    })

    return (
        <TwoColumnPage
            header={<Header />}
            leftContent={<LeftContent />}
            rightContent={solutionBoxes}
            title={'À propos'}
            rootUrl={config.ROOT_URL}
            titleImageUrl={aboutIllustration}
            solutionData={{
                title: 'À propos',
                name: 'objets'
            }}
        />
    )
}

export default withAppData(About, ['solution'])
