import React, { useState } from 'react'
import withSizes from 'react-sizes'
import { connect } from 'react-redux'
import {
    theme,
    Map,
    TwoColumnPageWithMap,
    MapInfoBoxContent1,
    MapInfoBoxContent2,
    MapInfoBoxContent3
} from 'issy-components'

import PageLeftContent from './PageLeftContent'
import Header from './Header'

import config from './config'
import leaflet from 'leaflet'

import isEqual from 'lodash.isequal'

import { pointListBounds } from './utils'
import { mapSetSelectedPin, mapResetSuccess } from './redux/actions'
import { getMostRecentUserPosition } from './redux/selectors'
import styled from 'styled-components'

export const DEFAULT_PADDING = [50, 150]
export const MOBILE_PADDING = [0, 0]
const COLOR_DEFAULT = theme.colors.Ocean
const COLOR_ACTIVE = theme.colors.NightBlue

class MapPins extends React.Component {
    constructor(props) {
        super(props)
        this.leafletMap = null
        this.leafletPins = null
        this.state = {
            overPinIndex: null
        }
    }

    onLeafletMapCreated = (leafletMap) => {
        this.leafletMap = leafletMap
        this.updatePins()
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedPinIndex !== prevProps.selectedPinIndex) {
            this.updateSelectedPin()
        }

        if (this.props.pins !== prevProps.pins) {
            this.updatePins()
        }

        if (
            (this.props.resetRequested && !prevProps.resetRequested) ||
            !isEqual(this.props.userPosition, prevProps.userPosition)
        ) {
            this.resetMapPositionZoom()
        }
    }

    updateSelectedPin() {
        if (!this.leafletMap) {
            return
        }
        if (this.props.selectedPinIndex !== null) {
            this.leafletMap.panTo(
                this.props.pins[this.props.selectedPinIndex].point
            )
        }
        this.updatePinStyles()
    }

    updatePins() {
        if (!this.props.pins || this.leafletPins !== null || !this.leafletMap) {
            return
        }

        // Sort points so that the path is displayed in a sensible way
        // (the path happens to follow more of less a horizontal line, so we can just sort by longitude )
        const points = this.props.pins.map((pin) => pin.point)
        points.sort((a, b) => a[1] - b[1])
        this.polyline = leaflet
            .polyline(points, { weight: 10, color: COLOR_DEFAULT })
            .addTo(this.leafletMap)

        this.leafletPins = this.props.pins.map((pin, i) => {
            const onClick = (a) => {
                this.leafletMap.panTo(pin.point)
                this.props.mapSetSelectedPin(i)
            }
            const onMouseOver = () => {
                this.setState({ overPinIndex: i })
                this.updatePinStyles()
            }
            const onMouseOut = () => {
                if (this.state.overPinIndex === i) {
                    this.setState({ overPinIndex: null })
                    this.updatePinStyles()
                }
            }
            const leafletPin = leaflet
                .circle(pin.point, { radius: 20, fillOpacity: 1 })
                .addTo(this.leafletMap)
            leafletPin.on('click', onClick)
            leafletPin.on('mouseover', onMouseOver)
            leafletPin.on('mouseout', onMouseOut)
            return leafletPin
        })

        this.resetMapPositionZoom()
        this.updatePinStyles()
    }

    updatePinStyles() {
        if (!this.leafletPins || !this.leafletMap) {
            return
        }
        this.leafletPins.forEach((leafletPin, i) => {
            if (this.props.selectedPinIndex === i) {
                leafletPin.setStyle({ color: COLOR_ACTIVE })
            } else if (this.state.overPinIndex === i) {
                leafletPin.setStyle({ color: COLOR_ACTIVE })
            } else {
                leafletPin.setStyle({ color: COLOR_DEFAULT })
            }
        })
    }

    resetMapPositionZoom() {
        const points = this.props.pins.map((pin) => pin.point)
        let bounds = pointListBounds(points)
        if (this.props.userPosition) {
            bounds = pointListBounds([...bounds, this.props.userPosition])
        }
        let padding = DEFAULT_PADDING
        if (this.props.isPhablet) {
            padding = MOBILE_PADDING
        }
        this.leafletMap.fitBounds(bounds, { padding })
        this.props.mapResetSuccess()
    }

    render() {
        return (
            <div>
                <Map
                    {...this.props}
                    onLeafletMapCreated={this.onLeafletMapCreated}
                    maxZoom={config.MAP_MAX_ZOOM}
                    minZoom={config.MAP_MIN_ZOOM}
                />
            </div>
        )
    }
}

const MapPinsWithSizes = withSizes(({ height, width }) => {
    return {
        windowHeight: height,
        windowWidth: width,
        isMobile: width < theme.devices.mobile,
        isPhablet: width < theme.devices.phablet
    }
})(MapPins)

const ConnectedMapPins = connect(
    (state) => ({
        resetRequested: state.map.resetRequested,
        userPosition: getMostRecentUserPosition(state)
    }),
    { mapSetSelectedPin, mapResetSuccess }
)(MapPinsWithSizes)

const MapInfoBoxContentDescription = styled(MapInfoBoxContent3)`
    max-height: 8em;
    overflow: auto;
`

const ResponseContainer = styled.div`
    margin-top: 1em;
`

const ResponseButton = styled.button`
    ${theme.mixins.buttonBlue}
`

const MapInfoBoxContent = ({ mapSelectedPin }) => {
    const [responseShownForPin, setResponseShown] = useState(null)
    const _onShowResponseClick = () => setResponseShown(mapSelectedPin)
    if (!mapSelectedPin) {
        return null
    }
    return (
        <div>
            <MapInfoBoxContent1>{mapSelectedPin.title}</MapInfoBoxContent1>
            <MapInfoBoxContent2>{mapSelectedPin.question}</MapInfoBoxContent2>
            <MapInfoBoxContentDescription>
                <div>{mapSelectedPin.description}</div>
                <ResponseContainer>
                    <ResponseButton onClick={_onShowResponseClick}>
                        {responseShownForPin === mapSelectedPin
                            ? mapSelectedPin.answer
                            : 'Afficher la réponse'}
                    </ResponseButton>
                </ResponseContainer>
            </MapInfoBoxContentDescription>
        </div>
    )
}

const RightContent = ({
    mapPins,
    mapSelectedPinIndex,
    mapSelectedPin,
    solutionData
}) => {
    let infoBoxContent = null
    if (mapSelectedPin) {
        infoBoxContent = <MapInfoBoxContent mapSelectedPin={mapSelectedPin} />
    }

    return (
        <div>
            <ConnectedMapPins
                infoBoxContent={infoBoxContent}
                canInfoBoxBeCollapsed={false}
                callToAction={solutionData.callToAction}
                urlSeeData={solutionData.urlSeeData}
                pins={mapPins}
                selectedPinIndex={mapSelectedPinIndex}
            />
        </div>
    )
}

const PageParcoursIle = ({
    solutionData,
    mapPins,
    mapSelectedPinIndex,
    mapSelectedPin,
    pageConfig
}) => {
    const rightContent = (
        <RightContent
            mapPins={mapPins}
            mapSelectedPinIndex={mapSelectedPinIndex}
            mapSelectedPin={mapSelectedPin}
            solutionData={solutionData}
        />
    )

    const leftContent = (
        <PageLeftContent
            textIntro={solutionData.textIntro}
            didYouKnow={solutionData.didYouKnow}
        />
    )

    return (
        <TwoColumnPageWithMap
            header={<Header />}
            leftContent={leftContent}
            rightContent={rightContent}
            rootUrl={config.ROOT_URL}
            titleImageUrl={pageConfig.pageTitleIcon}
            title={solutionData.title}
            lineColor={theme.colors.GardenLight}
        />
    )
}

export default PageParcoursIle
