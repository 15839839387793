import React from 'react'
import { Header as IssyHeader } from 'issy-components'
import config from './config'

const Header = () => {
    return (
        <IssyHeader
            rootUrl={config.ROOT_URL}
            siteUrl={config.SITE_URL}
            tweetShareText={config.TWEET_SHARE_TEXT}
            aboutUrl={`${config.ROOT_URL}/about`}
        />
    )
}

export default Header
