import React from 'react'
import styled from 'styled-components'
import leaflet from 'leaflet'
import {
    theme,
    MapPins,
    MapInfoBoxContent1,
    MapInfoBoxContent2,
    MapInfoBoxContent3,
    MapInfoBoxContentLabel,
    MapInfoBoxContentIcon,
    TwoColumnPageWithMap
} from 'issy-components'

import PageLeftContent from './PageLeftContent'
import Header from './Header'
import iconLeaves from './assets/issy-green-awesomefont_icones/issy-green-awesomefont_icone-arbreremarquable.svg'

import pinIcon from './assets/map/issy-green-marqueur_1.svg'
import pinIconSelected from './assets/map/issy-green-marqueur_2.svg'

import config from './config'

const TreeImage = styled.img`
    width: 50%;
    @media (max-width: ${theme.devices.mobile}px) {
        width: 100%;
    }
`

const SeeMoreButton = styled.a`
    margin-top: 1em;
    ${theme.mixins.buttonBlue}
`

const MapInfoBoxContent = ({ mapSelectedPin }) => {
    if (!mapSelectedPin) {
        return null
    }
    return (
        <div>
            <MapInfoBoxContent1>
                {mapSelectedPin.nomFrancais}
            </MapInfoBoxContent1>

            {mapSelectedPin.nomLatin ? (
                <MapInfoBoxContent2>
                    <MapInfoBoxContentIcon src={iconLeaves} alt='' />
                    <MapInfoBoxContentLabel>
                        {mapSelectedPin.nomLatin}
                    </MapInfoBoxContentLabel>
                </MapInfoBoxContent2>
            ) : null}

            {mapSelectedPin.description ? (
                <MapInfoBoxContent3>
                    {mapSelectedPin.description}
                </MapInfoBoxContent3>
            ) : null}

            {mapSelectedPin.imageUrl ? (
                <MapInfoBoxContent3>
                    <TreeImage src={mapSelectedPin.imageUrl} alt={mapSelectedPin.nomFrancais} />
                </MapInfoBoxContent3>
            ) : null}

            {mapSelectedPin.url ? (
                <MapInfoBoxContent3>
                    <SeeMoreButton href={mapSelectedPin.url} target="_blank">
                        Voir plus
                    </SeeMoreButton>
                </MapInfoBoxContent3>
            ) : null}
        </div>
    )
}

const RightContent = ({
    mapPins,
    mapSelectedPinIndex,
    mapSelectedPin,
    solutionData
}) => {
    let pins = undefined
    if (mapPins) {
        pins = mapPins.map((pin) => ({
            ...pin,
            icon: leaflet.icon({
                iconUrl: pinIcon
            }),
            selectedIcon: leaflet.icon({
                iconUrl: pinIconSelected
            })
        }))
    }

    let infoBoxContent = null
    if (mapSelectedPin) {
        infoBoxContent = <MapInfoBoxContent mapSelectedPin={mapSelectedPin} />
    }

    return (
        <MapPins
            pins={pins}
            infoBoxContent={infoBoxContent}
            callToAction={solutionData.callToAction}
            urlSeeData={solutionData.urlSeeData}
            selectedPinIndex={mapSelectedPinIndex}
            maxZoom={config.MAP_MAX_ZOOM}
            minZoom={config.MAP_MIN_ZOOM}
        />
    )
}

const PageArbreRemarquable = ({
    solutionData,
    mapPins,
    mapSelectedPinIndex,
    mapSelectedPin,
    pageConfig
}) => {
    const rightContent = (
        <RightContent
            mapPins={mapPins}
            mapSelectedPinIndex={mapSelectedPinIndex}
            mapSelectedPin={mapSelectedPin}
            solutionData={solutionData}
        />
    )

    const leftContent = (
        <PageLeftContent
            textIntro={solutionData.textIntro}
            didYouKnow={solutionData.didYouKnow}
        />
    )

    return (
        <TwoColumnPageWithMap
            header={<Header />}
            leftContent={leftContent}
            rightContent={rightContent}
            rootUrl={config.ROOT_URL}
            titleImageUrl={pageConfig.pageTitleIcon}
            title={solutionData.title}
            lineColor={theme.colors.Alpine}
        />
    )
}

export default PageArbreRemarquable
