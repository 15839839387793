import ReactGA from 'react-ga'
import { put, takeEvery } from 'redux-saga/effects'

import { geoLocationError, geoLocationSuccess } from '../actions'

const getCurrentPosition = () =>
    new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((point) => {
            const position = [point.coords.latitude, point.coords.longitude]
            ReactGA.event({
                category: 'geolocalisation',
                action: 'click',
                label: JSON.stringify(position)
            })
            return resolve(position)
        }, reject)
    })

function* requestCurrentPosition() {
    try {
        const point = yield getCurrentPosition()
        yield put(geoLocationSuccess(point))
    } catch (error) {
        yield put(geoLocationError(error))
    }
}

export function* geoLocationRequestSaga() {
    yield takeEvery('geoLocationRequest', requestCurrentPosition)
}
