import config from './config'

export const buildAppDataSheetUrl = (sheetId) => {
    return `https://sheets.googleapis.com/v4/spreadsheets/${config.APP_DATA_GSHEET_ID}/values/${sheetId}?key=${config.GOOGLE_API_KEY}`
}

// Migration google sheets API v3 to API v4
export const convertDataV3ToV4 = (data) => {
    // SCRIPT DE MAPPING START
    const keys = data.values[0]
    data.values.shift()
    let dataMappedV3ToV4 = {
        feed: {
            entry: data.values.map((fEntry, fEntryIndex) => {
                let returnedEntry = {}
                keys.forEach((fKey, fKeyIndex) => {
                    returnedEntry['gsx$' + fKey.split('_').join('')] = {
                        $t: fEntry[fKeyIndex]
                    }
                })
                return returnedEntry
            })
        }
    }
    return dataMappedV3ToV4
    //  SCRIPT DE MAPPING END
}

export const buildAddressQueryUrl = (query) => {
    return `https://data.issy.com/api/records/1.0/search/?dataset=adresses-a-issy-les-moulineaux&q=${query}&sort=commune&facet=commune&facet=cp`
}

export const fetchJSON = (url) => fetch(url).then((response) => response.json())

export const multiPolygonListBounds = (multiPolygons) => {
    let maxLat = null
    let maxLng = null
    let minLat = null
    let minLng = null
    multiPolygons.forEach((multiPolygon) => {
        multiPolygon.forEach((polygon) => {
            polygon.forEach((polyline) => {
                polyline.forEach((point) => {
                    // Initialization
                    if (maxLat === null) {
                        maxLat = point[0]
                        minLat = point[0]
                        maxLng = point[1]
                        minLng = point[1]
                    } else {
                        maxLat = Math.max(point[0], maxLat)
                        minLat = Math.min(point[0], minLat)
                        maxLng = Math.max(point[1], maxLng)
                        minLng = Math.min(point[1], minLng)
                    }
                })
            })
        })
    })
    return [
        [minLat, minLng],
        [maxLat, maxLng]
    ]
}

export const pointListBounds = (points) => {
    let maxLat = null
    let maxLng = null
    let minLat = null
    let minLng = null
    points.forEach((point) => {
        // Initialization
        if (maxLat === null) {
            maxLat = point[0]
            minLat = point[0]
            maxLng = point[1]
            minLng = point[1]
        } else {
            maxLat = Math.max(point[0], maxLat)
            minLat = Math.min(point[0], minLat)
            maxLng = Math.max(point[1], maxLng)
            minLng = Math.min(point[1], minLng)
        }
    })
    return [
        [minLat, minLng],
        [maxLat, maxLng]
    ]
}

// Take either GeoJSON Polygon or MultiPolygon and returns a normalized list of multi polygons:
// [ [ [ point1, point2, point3, ... ], [...] ] ]
// MultiPolygon = List<Polygon>; Polygon = List<PolyLine> ; PolyLine = List<Point>
// Polygons can have several closed polylines, cause they can have holes (think of a donut)
// - Also filter last point as recommended in leaflet docs
// - Also inverts coordinates as geoJSON is lng, lat
export const normalizeGeoJsonShape = (geoJsonShape) => {
    if (geoJsonShape.type === 'Polygon') {
        return [_normalizePolygon(geoJsonShape.coordinates)]
    } else if (geoJsonShape.type === 'MultiPolygon') {
        return geoJsonShape.coordinates.map(_normalizePolygon)
    } else if (geoJsonShape.type === 'LineString') {
        return [geoJsonShape.coordinates.map(_invertCoordinates)]
    } else if (geoJsonShape.type === 'MultiLineString') {
        return geoJsonShape.coordinates.map((line) =>
            line.map(_invertCoordinates)
        )
    } else {
        console.error(geoJsonShape)
        throw new Error(`unsupported geoJson type ${geoJsonShape.type}`)
    }
}

export const normalizeGeoJsonPosition = (geoJsonPoint) => {
    if (geoJsonPoint.type === 'Point') {
        return _invertCoordinates(geoJsonPoint.coordinates)
    } else {
        throw new Error(`unsupported geoJson type ${geoJsonPoint.type}`)
    }
}

export const normalizeGeoPoint2d = (geoPoint2d) => {
    const coords = geoPoint2d.split(', ')
    return [parseFloat(coords[0]), parseFloat(coords[1])]
}

const _normalizePolygon = (polygon) => {
    return polygon.map((polyline) => {
        polyline = _filterOutPolylineDuplicateLastPoint(polyline)
        return polyline.map(_invertCoordinates)
    })
}

const _filterOutPolylineDuplicateLastPoint = (polyline) => {
    const first = polyline[0]
    const last = polyline.slice(-1)[0]
    if (first[0] === last[0] && first[1] === last[1]) {
        return polyline.slice(0, -1)
    } else {
        return polyline
    }
}

const _invertCoordinates = (point) => [point[1], point[0]]

export const cleanAddressQueryResults = (query, addressQueryResults) => {
    if (addressQueryResults.records.length === 0) {
        return null
    }
    return addressQueryResults.records.map((record) => ({
        address: capitalizeString(record.fields.adresse).replace(
            'issy-les-moulineaux',
            ''
        ),
        point: record.fields.geo_point_2d
    }))
}

export const searchString = (items, itemKeyField, query, maxResults) => {
    const normalizedQuery = query.toLowerCase()
    const searchResults = []
    items.some((item) => {
        const itemKey = item[itemKeyField]
        // Remove accents : https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        const normalizedItemKey = itemKey
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        // Avoid duplicates
        if (searchResults.filter((r) => r.key === normalizedItemKey).length) {
            return false
        }
        const matchIndex = normalizedItemKey.indexOf(normalizedQuery)
        if (matchIndex > -1) {
            const preMatch = itemKey.slice(0, matchIndex)
            const match = itemKey.slice(matchIndex, matchIndex + query.length)
            const postMatch = itemKey.slice(matchIndex + query.length)
            searchResults.push({
                match: {
                    length: query.length,
                    index: matchIndex,
                    preMatch,
                    match,
                    postMatch
                },
                key: normalizedItemKey,
                item
            })
        }
        return searchResults.length >= maxResults
    })
    return searchResults
}

export const capitalizeString = (str) =>
    str[0].toUpperCase() + str.slice(1).toLowerCase()
