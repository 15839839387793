import React from 'react'
import { theme, TwoColumnPageWithMap } from 'issy-components'
import mapValues from 'lodash.mapvalues'

import MapWithPaths from './MapWithPaths'
import PageLeftContent from './PageLeftContent'
import Header from './Header'

import config from './config'
import Legend from './Legend'

const MAP_MAX_ZOOM = 18
const MAP_MIN_ZOOM = 12

const LANE_COLOR = theme.colors.GardenLight
const STRIPE_COLOR = theme.colors.Ocean

const LEGEND_ITEMS = [
    {
        label: 'Piste cyclable',
        color: LANE_COLOR,
    },
    {
        label: 'Bande cyclable',
        color: STRIPE_COLOR,
    },
]

const RightContent = ({ mapPaths, solutionData }) => {
    const legendContent = <Legend legendItems={LEGEND_ITEMS} />
    return (
        <MapWithPaths
            mapPaths={mapPaths}
            callToAction={solutionData.callToAction}
            urlSeeData={solutionData.urlSeeData}
            maxZoom={MAP_MAX_ZOOM}
            minZoom={MAP_MIN_ZOOM}
            legendContent={legendContent}
        />
    )
}

const PageVoiesCyclables = ({ solutionData, appData, pageConfig }) => {
    const mapPaths = mapValues(appData[solutionData.name], (element) => {
        const color = ({
            cycling_lane: LANE_COLOR,
            cycling_stripe: STRIPE_COLOR,
        })[element.pathType]
        if (!color) {
            console.error(`invalid path type ${element.pathType}`)
            return element
        }
        return {
            style: {
                color
            },
            ...element
        }
    })

    const rightContent = (
        <RightContent mapPaths={mapPaths} solutionData={solutionData} />
    )

    const leftContent = (
        <PageLeftContent
            textIntro={solutionData.textIntro}
            didYouKnow={solutionData.didYouKnow}
        />
    )

    return (
        <TwoColumnPageWithMap
            header={<Header />}
            leftContent={leftContent}
            rightContent={rightContent}
            rootUrl={config.ROOT_URL}
            titleImageUrl={pageConfig.pageTitleIcon}
            title={solutionData.title}
            lineColor={theme.colors.Citron}
        />
    )
}

export default PageVoiesCyclables
