import { withPinsMap, withAppData, withPinsMapNoUserProximity } from './hoc'
import {
    normalizeGeoJsonShape,
    normalizeGeoPoint2d,
    capitalizeString
} from './utils'
import config from './config'
import PageArbreRemarquable from './PageArbreRemarquable'
import PageParcs from './PageParcs'
import PageRandonnee from './PageRandonnee'
import PageVoiesCyclables from './PageVoiesCyclables'
import PageParcoursIle from './PageParcoursIle'

import parcsHomeStartItemIcon from './assets/issy-green-pictos-SVG/issy-green-picto_sedetendre.svg'
import parcsPageTitleIcon from './assets/issy-green-illus-SVG/issy-green-illu_sedetendre.svg'

import arbreRemarquableHomeStartItemIcon from './assets/issy-green-pictos-SVG/issy-green-picto_arbreremarquable.svg'
import arbreRemarquablePageTitleIcon from './assets/issy-green-illus-SVG/issy-green-illu_arbreremarquable.svg'

import randonneeHomeStartItemIcon from './assets/issy-green-pictos-SVG/issy-green-picto_mepromener.svg'
import randonneePageTitleIcon from './assets/issy-green-illus-SVG/issy-green-illu_mepromener.svg'

import voiesCyclablesHomeStartItemIcon from './assets/issy-green-pictos-SVG/issy-green-picto_faireduvelo.svg'
import voiesCyclablesPageTitleIcon from './assets/issy-green-illus-SVG/issy-green-illu_faireduvelo.svg'

import parcoursIleHomeStartItemIcon from './assets/issy-green-pictos-SVG/issy-green-picto_stgermain.svg'
import parcoursIlePageTitleIcon from './assets/issy-green-illus-SVG/issy-green-illu_stgermain.svg'

const PARCS_PAGE_ID = 'parcs'
const ARBRES_REMARQUABLES_PAGE_ID = 'arbres_remarquable'
const RANDONNEE_PAGE_ID = 'randonnee'
const VOIES_CYCLABLES_ID = 'voies_cyclables'
const PARCOURS_ILE_ID = 'parcours_ile'

export default {
    [PARCS_PAGE_ID]: {
        gSheetIndex: 'parcs',
        url: `${config.ROOT_URL}/${PARCS_PAGE_ID}`,
        cleanData: (data) => {
            return data.map((row, i) => {
                return {
                    polygons: normalizeGeoJsonShape(
                        JSON.parse(row.gsx$geoshape.$t)
                    ),
                    nom: row.gsx$NOMPARCJARDIN.$t,
                    horaires: row.gsx$HORAIRE.$t,
                    sanitaires: row.gsx$SANITAIRE.$t === 'Oui' ? true : false,
                    fontaine: row.gsx$BORNEFONTAINE.$t === 'Oui' ? true : false,
                    airejeu: row.gsx$AIREJEU.$t === 'Oui' ? true : false,
                    piquenique: row.gsx$PIQUENIQUE.$t === 'Oui' ? true : false,
                    localisation: capitalizeString(
                        row.gsx$LOCALISATION.$t.toLowerCase()
                    )
                }
            })
        },
        component: withAppData(PageParcs, [PARCS_PAGE_ID]),
        homeStartItemIcon: parcsHomeStartItemIcon,
        pageTitleIcon: parcsPageTitleIcon
    },

    [ARBRES_REMARQUABLES_PAGE_ID]: {
        gSheetIndex: 'arbres_remarquable',
        url: `${config.ROOT_URL}/${ARBRES_REMARQUABLES_PAGE_ID}`,
        cleanData: (data) => {
            return data.map((row, i) => {
                return {
                    point: normalizeGeoPoint2d(row.gsx$GeoPoint.$t),
                    nomFrancais: row.gsx$NOMFRANCAIS.$t,
                    nomLatin: row.gsx$NOMLATIN.$t,
                    description: row.gsx$Description.$t,
                    imageUrl: row.gsx$image.$t,
                    url: row.gsx$url.$t,
                }
            })
        },
        component: withAppData(
            withPinsMap(PageArbreRemarquable, ARBRES_REMARQUABLES_PAGE_ID),
            [ARBRES_REMARQUABLES_PAGE_ID]
        ),
        homeStartItemIcon: arbreRemarquableHomeStartItemIcon,
        pageTitleIcon: arbreRemarquablePageTitleIcon
    },

    [RANDONNEE_PAGE_ID]: {
        gSheetIndex: 'voies_piétonnes',
        url: `${config.ROOT_URL}/${RANDONNEE_PAGE_ID}`,
        cleanData: (data) => {
            const paths = {}

            data.forEach((row, i) => {
                const id = i
                const shape = JSON.parse(row.gsx$geoshape.$t)
                let pathType = null
                if (row.gsx$Nomvoie.$t === 'Autre') {
                    pathType = 'other'
                } else if (row.gsx$Nomvoie.$t === 'Promenade bleue') {
                    pathType = 'blue'
                } else if (["Promenade verte d'intérêt local", 'Promenade verte d\'intérêt départemental'].includes(row.gsx$Nomvoie.$t)) {
                    pathType = 'green'
                
                // Ignore other type of ways 
                } else {
                    return 
                }

                paths[id] = {
                    id,
                    path: normalizeGeoJsonShape(shape),
                    pathType,
                }
            })
            return paths
        },
        component: withAppData(PageRandonnee, [RANDONNEE_PAGE_ID]),
        homeStartItemIcon: randonneeHomeStartItemIcon,
        pageTitleIcon: randonneePageTitleIcon
    },

    [VOIES_CYCLABLES_ID]: {
        gSheetIndex: 'new_cyclable',
        url: `${config.ROOT_URL}/${VOIES_CYCLABLES_ID}`,
        cleanData: (data) => {
            const paths = {}
            data.forEach((row, i) => {
                const id = i
                const shape = JSON.parse(row.gsx$geoshape.$t)
                let pathType = null
                if (row.gsx$TYPEAMGTCYCL.$t === 'Bande cyclable') {
                    pathType = 'cycling_stripe'
                } else if (row.gsx$TYPEAMGTCYCL.$t === 'Piste cyclable') {
                    pathType = 'cycling_lane'
                
                // Ignore other type of ways 
                } else {
                    return 
                }

                paths[id] = {
                    id,
                    path: normalizeGeoJsonShape(shape),
                    pathType,
                }
            })
            return paths
        },
        component: withAppData(PageVoiesCyclables, [VOIES_CYCLABLES_ID]),
        homeStartItemIcon: voiesCyclablesHomeStartItemIcon,
        pageTitleIcon: voiesCyclablesPageTitleIcon
    },

    [PARCOURS_ILE_ID]: {
        gSheetIndex: 'parcours_ile',
        url: `${config.ROOT_URL}/${PARCOURS_ILE_ID}`,
        cleanData: (data) => {
            return data.map((row, i) => {
                const id = i
                const point = normalizeGeoPoint2d(row.gsx$Coordonnée.$t)
                return {
                    id,
                    point,
                    title: row.gsx$Titre.$t,
                    question: row.gsx$Question.$t,
                    description: row.gsx$Description.$t,
                    answer: row.gsx$Réponse.$t
                }
            })
        },
        component: withAppData(withPinsMapNoUserProximity(PageParcoursIle, PARCOURS_ILE_ID), [
            PARCOURS_ILE_ID
        ]),
        homeStartItemIcon: parcoursIleHomeStartItemIcon,
        pageTitleIcon: parcoursIlePageTitleIcon
    },

    solution: {
        gSheetIndex: 'solution',
        cleanData: (rawData) => {
            return rawData.map((row) => {
                return {
                    title: row.gsx$solutiontitre.$t,
                    name: row.gsx$solution.$t,
                    longName: row.gsx$solutionlongue.$t,
                    textIntro: row.gsx$pagetexteintro.$t,
                    didYouKnow: row.gsx$pagetextesaviezvous.$t,
                    urlMoreInfo: row.gsx$urlplusinfo.$t,
                    urlSeeData: row.gsx$urlvoirdonnees.$t,
                    callToAction: row.gsx$calltoaction.$t,
                    boxText: row.gsx$cartouchetexte.$t
                }
            })
        }
    }
}
