import { put, takeEvery, all } from 'redux-saga/effects'

import { appDataFetchError, appDataFetchSuccess } from '../actions'
import { buildAppDataSheetUrl, fetchJSON, convertDataV3ToV4 } from '../../utils'
import PAGE_CONFIGS from '../../page-configs'

function* requestAppDataSingleSheet(sheetName) {
    const sheetId = PAGE_CONFIGS[sheetName].gSheetIndex
    if (sheetId === undefined) {
        return put(
            appDataFetchError(
                sheetName,
                new Error(`Unknown sheet name ${sheetName}`)
            )
        )
    }

    const dataCleaner = PAGE_CONFIGS[sheetName].cleanData
    try {
        const data = yield fetchJSON(buildAppDataSheetUrl(sheetId))
        const convertedData = convertDataV3ToV4(data)
        const cleanData = dataCleaner(convertedData.feed.entry)
        yield put(appDataFetchSuccess(sheetName, cleanData))
    } catch (error) {
        yield put(appDataFetchError(sheetName, error))
    }
}

function* requestAppData(action) {
    yield all(
        action.payload.sheetNames
            .filter((sheetName) => sheetName in PAGE_CONFIGS)
            .map(requestAppDataSingleSheet)
    )
}

export function* appDataFetchRequestSaga() {
    yield takeEvery('appDataFetchRequest', requestAppData)
}
