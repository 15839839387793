import React from 'react'
import styled from 'styled-components'


const LegendUl = styled.ul`
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0.5em 1em;
    height: 3em;
    align-items: center;

    li {
        min-width: 7em;
        padding-left: 1em;
        display: flex;
    }

    li:first-child {
        padding-left: 0;
    }

    li > * {
        display: inline-block;
        align-self: center;
    }
`

const LegendColor = styled.span`
    display: inline-block;
    margin-right: 0.5em;
    font-size: 60%;
    font-weight: 900;
    background-color: ${props => props.color};
    border-radius: 1em;
    height: 2em;
    width: 2em;
`

const Legend = ({legendItems}) => {
    const liElems = legendItems.map(({label, color}) => (
        <li key={label}>
            <LegendColor color={color} />
            <span>{label}</span>
        </li>
    ))
    return (
        <LegendUl>
            {liElems}
        </LegendUl>
    )
}


export default Legend