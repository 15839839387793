export default {
    SITE_URL: 'https://balades.issy.com',
    TWEET_SHARE_TEXT:
        '#Deconfinement : les espaces verts sont à nouveau accessibles ! Profitez-en pour vous promener et (re)découvrir les parcs et jardins du territoire ! Rendez-vous sur le site balades.issy.com pour concocter votre parcours pédestres ou à vélos.',

    ROOT_URL: '',
    ISSY_DATA_URL: 'https://data.issy.com/',
    WEDODATA_URL: 'https://wedodata.fr',
    QUE_FAIRE_A_ISSY_URL:
        'https://sortir.issy.com/agenda/?oaq%5Bwhat%5D=collecte+solidaire#agenda',
    APP_DATA_GSHEET_ID: '1bhjN7h_07ZwsGHPsJq7UhvTCYek6iW-h3BXSifyj3AU',
    GOOGLE_API_KEY: 'AIzaSyDgstkPPHTfWo5BWYN5mF69CtcRaHpgPhs',

    MAP_INITIAL_ZOOM: 13,
    MAP_MAX_ZOOM: 18,
    MAP_MIN_ZOOM: 12,

    GOOGLE_ANALYTICS_ID: 'UA-51867236-10'
}
